<template>
  <div>
    <p class="text-subhead-3 mb-1">New message{{ isGroupNotification ? 's' : '' }}</p>
    <div class="text-body-2 text-black-80">
      <template v-if="isGroupNotification">
        <span class="text-subhead-4">{{ data.length }} leads</span> sent you new messages while you was away.
        <UiButtonBase id="goToLeads" type="secondary" size="small" class="mt-2" @click="goToLeads">
          Open leads
        </UiButtonBase>
      </template>
      <template v-else>
        <button id="goToLead" class="text-subhead-4 underline" @click="goToLead">{{ data.lead_name }}</button> sent you
        message in WhatsApp.
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui'
import type { LibraryItem } from '@/types'

const uiStore = useUiStore()
const emits = defineEmits(['close'])
type Props = {
  data: any
}

const WA_UNREAD_QUICK_FILTER = 5

const props = defineProps<Props>()

const isGroupNotification = computed(() => Array.isArray(props.data))

const waUnreadQuickFilterId = ref()

onNuxtReady(async () => {
  if (isGroupNotification.value) {
    const { data, error } = await usePipelineSalesQuickFilters()
    if (error.value) uiStore.showSnackBanner(error.value.message, 'error')
    waUnreadQuickFilterId.value = data.value?.data.find((item: LibraryItem) => item.id === WA_UNREAD_QUICK_FILTER).id
  }
})

const goToLeads = () => {
  navigateTo(`/pipelines/sales?quick_filter=${waUnreadQuickFilterId.value}`)
  emits('close')
}

const goToLead = () => {
  navigateTo(`/leads/${props.data.lead_id}#whatsapp`)
  emits('close')
}
</script>

<style scoped></style>
